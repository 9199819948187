import React, { useState } from 'react';
//import cx from "classnames";
import Typography from '@material-ui/core/Typography';
import styles from "./style.module.css";

import blocks_platform from "../../assets/images/SIMBA_Blocks.pdf";
import { Document, Page, pdfjs } from 'react-pdf';
import blocks_logo from "../../assets/images/SIMBA_Blocks_logo.png";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const Blocks = props => {
    React.useEffect(() => {
        document.title = `Simba Blocks`;

    });
    const [numPages, setNumPages] = useState(2);
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }
    return (
        <div className={styles.container}>

            <Typography className={styles.h4_title} variant="h4" >
                <img className={styles.item_logo} src={blocks_logo} />
            </Typography>
            <Typography>
                <Document file={blocks_platform} onDocumentComplete={onDocumentLoadSuccess}
                    onLoadError={() => {
                        console.log("CALLED");
                    }}
                    onLoadProgress={() => {
                        console.log("PROGRESS");
                    }}
                >
                    {Array.apply(null, Array(numPages))
                        .map((x, i) => i + 1)
                        .map(page => <Page scale={2.0} pageNumber={page} />)}
                </Document>
            </Typography>
        </div >

    );
};


