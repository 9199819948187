import React, { useCallback, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, Grid, Table, TableBody, TableCell, TableHead, TableRow, TextField, Paper } from "@material-ui/core";
import { getTokenURI } from "../../core/interactions_blocks";
import { TitleMint } from "../../components/TitleMint";
import constants from '../../configs/constants';
import css from './styles.module.css';

const styles = () => ({
  table: {
    minWidth: 700
  }
});

const TokenURIView = ({ classes }) => {
  const [tokenId, setTokenId] = useState('');
  const [rows, setRows] = useState([]);

  const handleSubmit = useCallback(async (evt) => {
    evt.preventDefault();
    try {
      const tokenURIData = await getTokenURI(constants.NFT_DROP_CONTRACT_NAME, tokenId);
      const infoJsonString = decodeURIComponent(tokenURIData.value).split("data:application/json;base64,")[1];
      var infoJson = window.atob(infoJsonString);
      const info = JSON.parse(infoJson);
      info.image = decodeURIComponent(info.image);
      console.log(info)
      setRows([info]);
    } catch (e) {
      console.log(e);
    }
  }, [tokenId]);

  const handleTokenIdChange = useCallback(e => {
    setTokenId(e.target.value);
  }, []);

  return (
    <div className={css.container}>
      <Grid container
        direction="column">
        <Grid direction="column" >
          <TitleMint></TitleMint>
        </Grid>
        <Grid direction="column" >
          <form className={css.form}>
            <Grid justifyContent="space-between" direction="column">
              <TextField
                style={{ width: '100%' }}
                label="Please Enter the Token Id"
                value={tokenId}
                onChange={handleTokenIdChange}
                margin="normal"
              />
            </Grid>
            <Grid container item xs direction="column">
              <Button
                onClick={handleSubmit}
                disabled={!tokenId}
                variant="contained"
                style={{
                  backgroundColor: "#e7e9ea",
                }}>
                Get TokenURI
              </Button>

            </Grid>
          </form>
        </Grid>
        <Grid justifyContent="space-between" direction="column">
          {rows.length > 0 && <Paper>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Content hash</TableCell>
                  <TableCell>Image</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell>{row.description}</TableCell>
                    <TableCell>{row.contentHash}</TableCell>
                    <TableCell><img className={css.image} src={row.image} alt='' /></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>}
        </Grid>
      </Grid>
    </div>
  )
}

export default withStyles(styles)(TokenURIView)
