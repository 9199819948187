/* eslint-disable no-unused-vars */

//Needs to be refactored with IRAC model
import React, { Component } from 'react'
import { registerCollection, loadTreasury, registerCollectionToMDR } from "../../core/interactions/interactions";
import { nftcontractSelector, nftcontractAddressSelector, mdrcontractSelector, accountSelector, valueSelector, eventSelector, connectionSelector, marketplacecontractSelector } from "../../core/selectors/selectors";
import { Button, Icon, Dialog, Grid, Table, TableBody, Toolbar, IconButton, TableCell, TableHead, TableRow, TextField, Paper, Typography } from "@material-ui/core";
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { withRouter } from 'react-router-dom'
import { Form, Label, Input } from '../../components/Form'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'
import { styles } from './styles.scss'

class RegisterCollectionView extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }
  componentDidMount() {
    const {
      actions, asset, marketplace_contract, nftaddress
    } = this.props
    console.log('this props provider', actions)
    console.log('Asset props is mounted', asset)
    console.log('NFT Contract Address', marketplace_contract.address)
  }
  onEnter = async (evt) => {
    evt.preventDefault();
    const { connection, nftaddress, dispatch, marketplace_contract, nft_contract, account, value, events } = this.props;
    const formValue = await evt.currentTarget.form[0].value
    try {
      const collection_address = await nft_contract.address;
      await registerCollection(dispatch, marketplace_contract, formValue, account);
    } catch (error) {
    }
  }

  onEnterMDR = async (evt) => {
    evt.preventDefault();
    const { connection, dispatch, mdr_contract, nft_contract, account, value, events } = this.props;
    const collectionAddress = await evt.currentTarget.form[0].value
    try {
      // const collection_address = await nft_contract.address;
      await registerCollectionToMDR(dispatch, mdr_contract, collectionAddress, account);
    } catch (error) {
    }
  }
  render() {
    const { connection, nftaddress, dispatch, marketplace_contract, nft_contract, account, value, events } = this.props;
    return (
      <div className="flex justify-center" >
        <div className="w-1/2 flex flex-col pb-12">

          <section className="text-center">

            <form className="form">
              <Grid container justifyContent="space-between" justify="center" direction="column">
                <TextField

                  label="Please Enter the smart contract address"
                  id="outlined-required"
                  style={{ width: 400 }}
                  defaultValue={nftaddress}
                  margin="normal"
                />

                <Button

                  onClick={this.onEnter}
                  variant="contained"
                  style={{
                    backgroundColor: "#e7e9ea",
                  }}>
                  Register on Marketplace
                </Button>
              </Grid>
            </form>
          </section>

        </div>
      </div >
    )
  }
}
function mapStateToProps(state) {
  return {
    connection: connectionSelector(state),
    nftaddress: nftcontractAddressSelector(state),
    nft_contract: nftcontractSelector(state),
    account: accountSelector(state),
    events: eventSelector(state),
    marketplace_contract: marketplacecontractSelector(state),
    mdr_contract: mdrcontractSelector(state),
    value: valueSelector(state)
  }
}

export default withWidth()(withRouter(connect(mapStateToProps)(RegisterCollectionView)))