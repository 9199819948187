import React from "react";
//import cx from "classnames";
import Typography from '@material-ui/core/Typography';
import styles from "./style.module.css";
import * as LINKS from "../../configs/blue_constants";
import Blocks from "../../assets/images/blocks.png";
import logo from "../../assets/images/NFT-Block-logo-h-Blue.svg";
export const Introduction = props => {
    React.useEffect(() => {
        document.title = `Simba Blocks`;

    });

    return (
        <div className={styles.container}>

            <Typography className={styles.h4_title} variant="h4" >
                <img className={styles.item_logo} src={logo} />
            </Typography>

            <Typography variant="h5">
                <br></br>
                What is the NFT Block?
                <br></br>
            </Typography>
            <Typography className={styles.text} >
                The NFT Block is a turnkey solution for minting NFTs. It allows an enterprise to start creating NFTs immediately, without writing a line of code. Customers can then integrate the NFT Block’s APIs into their application to create NFTs programmatically.
                The NFT Block runs on top of the Platform Block, inheriting all its advantages, such as multi-chain support and transaction validation. Customers who start with the NFT Block and wish to go further can purchase a license to the Platform Block to begin creating their own smart contracts.
                Like the rest of SIMBA Blocks, the NFT Block is managed by SIMBA Chain. A customer does not need to install or run any software in their environment. Once they have a license to the NFT Block, they can begin using its web interface and API immediately.
            </Typography>
            <Typography >
                <img className={styles.item_img} src={Blocks} />
            </Typography>
        </div>
    );
};


