import React from "react";
//import cx from "classnames";
import Typography from '@material-ui/core/Typography';
import styles from "./style.module.css";
import mint from "../../assets/images/mint.png";
import events from "../../assets/images/events.png";
import library from "../../assets/images/library.png";
import fetch from "../../assets/images/fetch.png";
import authorize from "../../assets/images/authorize.png";
import logo from "../../assets/images/NFT-Block-logo-h-Blue.svg";
export const Mint_Docs = props => {
    React.useEffect(() => {
        document.title = `Simba Mint Block`;
    });
    return (
        <div className={styles.container}>
            <Typography className={styles.h4_title} variant="h4" >
                <img className={styles.item_logo} src={logo} />
            </Typography>
            <Typography variant="h5">
                <br></br>
                Create NFT Tab
                <br></br>
            </Typography>
            <Typography className={styles.text} >
                It's easy to create a new NFT.  All that you will need is a picture (10MB file size limit) and two descriptions.  One description can be used to describe the picture and the other description can be used to describe an event the NFT was minted for.
            </Typography>
            <img className={styles.item_img} src={mint} />
            <Typography variant="h5">
                <br></br>
                Events Tab
            </Typography>
            <Typography className={styles.text} >
                The Events tab will pull all the NFTs that are minted against your smart contract.  The TokenId contains some sophisticated logic which allows for the transfer to another smart contract without a token ID clash
            </Typography>
            <img className={styles.item_img} src={events} />
            <Typography variant="h5">
                <br></br>
                Library Tab
            </Typography>
            <Typography className={styles.text} >
                The Library tab is a gallery display of all the NFTs that the user has minted.
            </Typography>
            <img className={styles.item_img} src={library} />
            <Typography variant="h5">
                <br></br>
                Fetch Tab
            </Typography>
            <Typography className={styles.text} >
                The Fetch tab allows the user to paste in a tokenId to fetch the metadata.
            </Typography>
            <img className={styles.item_img} src={fetch} />
            <Typography variant="h5">
                <br></br>
                Authorize Tab
            </Typography>
            <Typography className={styles.text} >
                The Authorize tab allows the user to provision authorization to mint NFTs to another wallet address.
            </Typography>
            <img className={styles.item_img} src={authorize} />
        </div>

    );
};


