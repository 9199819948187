/* eslint-disable no-unused-vars */
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import configureStore from './core/store/configureStore'
import App from './containers/App/App'
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './styles/theme';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDD82p1UNgM23TmyXGkoCVOtSvpNE2nbxA",
    authDomain: "nftblockprod.firebaseapp.com",
    projectId: "nftblockprod",
    storageBucket: "nftblockprod.appspot.com",
    messagingSenderId: "957433953540",
    appId: "1:957433953540:web:92e8ef1bf847cbf5aae451",
    measurementId: "G-NL498Q7JZW"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const store = configureStore()

ReactDOM.render(

    <Provider store={store}>
        <App />
    </Provider>,

    document.getElementById('root')
)
