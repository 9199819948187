import React from "react";
import Drawer from "../Drawer";
import { SidebarStateContext } from "../SidebarContext";
import demoAppStyle from "../../configs/theme/demo_style.module.css";

export const SidebarContainer = ({ children, left, position }) => {
    let device = "desktop";

    return device === "mobile" || (device !== "desktop" && left) ? (
        <SidebarStateContext.Consumer>
            {({ leftSidebarState, rightSidebarState, setSidebarState }) => (
                <Drawer
                    isOpen={left ? leftSidebarState : rightSidebarState}
                    size={"240px"}
                    onClose={() =>
                        setSidebarState({
                            left: left ? !leftSidebarState : leftSidebarState,
                            right: left ? rightSidebarState : !rightSidebarState
                        })
                    }
                    style={{ padding: 0, overflowY: "auto" }}
                    position={position}
                    backdropClassName={!left && demoAppStyle.right_sidebar_backdrop}
                >
                    {children}
                </Drawer>
            )}
        </SidebarStateContext.Consumer>
    ) : (
        <>{children}</>
    );
};
