/* eslint-disable no-unused-vars */
import { connect } from 'react-redux'
import React, { Component, Fragment } from 'react'
import { loadWeb3, disconnect, switched, loadLibrary } from "../../core/interactions/interactions";
import { Button } from "../../components/Button"
import { Grid } from "../../components/Grid"
import { Cards } from "../../components/Cards"
import { Title } from "../../components/Title"
import { toHex } from "../../configs/utils";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import Box from '@material-ui/core/Box';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { withRouter } from 'react-router-dom'

import withWidth, { isWidthUp } from '@material-ui/core/withWidth'

import {
  nftcontractSelector,
  accountSelector,
  valueSelector,
  eventSelector,
  connectionSelector,
  chainIDSelector,
  providerSelector,
  marketplacecontractSelector,
  signerSelector,
  nftcontractAddressSelector
} from "../../core/selectors/selectors";

class ConnectionView extends Component {

  componentDidMount() {
    const {
      actions
    } = this.props
    console.log('this props provider', actions)
  }

  /*const handleChange = (event) => {
    console.log(event.target.value)
  }
*/
  connectBlockchain = async (e) => {
    e.preventDefault();
    const { dispatch } = this.props;
    const myWeb3 = await loadWeb3(dispatch);
    await loadLibrary(dispatch, myWeb3.nftinterfececontract, myWeb3.chainID, myWeb3.blocknumber);
    //subscribeToAccountsChanging(dispatch, myWeb3.account);
  };

  disconnectBlockchain = async (e) => {
    e.preventDefault();
    const { dispatch } = this.props;
    const myWeb3 = await disconnect(dispatch);
    //subscribeToAccountsChanging(dispatch, myWeb3);
  };

  handleNetwork = async (e) => {
    e.preventDefault();
    const { dispatch, chainID } = this.props;
    const idToHex = toHex(e.target.value)

    if (idToHex !== chainID) {
      await switched(dispatch, idToHex);
    }
  };
  render() {
    const { provider, account, chainID, nftaddress, connection } = this.props;
    console.log('Provider', provider)
    console.log('connection', connection)
    console.log('chainID', chainID)

    return (
      <div className="container py-2" >
        <div className="row justify-content-center">
          <Title></Title>
          <Box sx={{ width: '75%' }}>
            <div className="row flex-column">
              <div className="row justify-content-center">{!account ? (
                <Button onClick={this.connectBlockchain}>Connect Wallet</Button>
              ) : (
                <Button onClick={this.disconnectBlockchain}>Disconnect</Button>
              )}</div>
              <div className="row justify-content-center mt-2">
                {`Connection Status:  `}
                {account ? (
                  <CheckCircleIcon />
                ) : (
                  <ErrorIcon />
                )}</div>
              <div className="row justify-content-center mt-2">{`Account: ${account}`}</div>
              <div className="row justify-content-center mt-2">{`Smart Contract Deployed Address: ${nftaddress}`}</div>
              <div className="row justify-content-center mt-2"> {`Network ID: ${chainID ? chainID : "No Network"}`}</div>
              <div className="row justify-content-center mt-2">
                <ToggleButtonGroup
                  color="primary"
                  value={chainID}
                  exclusive
                  onChange={this.handleNetwork}>
                  <ToggleButton value="1">Eth Mainnet</ToggleButton>
                  <ToggleButton value="4">Eth Rinkeby</ToggleButton>
                  <ToggleButton value="80001">Polygon Mumbai</ToggleButton>
                  <ToggleButton value="137">Polygon Mainnet</ToggleButton>
                </ToggleButtonGroup>
              </div>
            </div>
          </Box>
        </div>
        <div className="row justify-content-center">
          <div className="col-4">
          </div>

        </div>
      </div >
    )
  }
}


function mapStateToProps(state) {
  return {
    connection: connectionSelector(state),
    provider: providerSelector(state),
    signer: signerSelector(state),
    nftaddress: nftcontractAddressSelector(state),
    nft_contract: nftcontractSelector(state),
    account: accountSelector(state),
    events: eventSelector(state),
    value: valueSelector(state),
    chainID: chainIDSelector(state)
  }
}

export default withWidth()(withRouter(connect(mapStateToProps)(ConnectionView)))
