import React from 'react'
import TwitterIcon from '@material-ui/icons/Twitter'
import FacebookIcon from '@material-ui/icons/Facebook'
import InstagramIcon from '@material-ui/icons/Instagram'
import EmailIcon from '@material-ui/icons/Email'
import css from './styles.module.css'

const Footer = () => {
  return <div className={css.footerBar}>
    <div className={css.text}>&copy; 2022 SIMBA Chain. All rights reserved.</div>
    <div className={css.contact}>
      <div className={css.contactLinks}>
        <a href="https://www.facebook.com/simbamrkt/" rel="noopener noreferrer" target="_blank"><FacebookIcon color="primary" /></a>
        <a href="https://twitter.com/SIMBAchain" rel="noopener noreferrer" target="_blank"><TwitterIcon color="primary" /></a>
        <a href="https://www.instagram.com/simbachain/" rel="noopener noreferrer" target="_blank"><InstagramIcon color="primary" /></a>
      </div>
      <p><EmailIcon /><a href="mailto:support@simbachain.com">support@simbachain.com</a></p>
    </div>
  </div>;
}

export default Footer
