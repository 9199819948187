/* eslint-disable no-unused-vars */
import Web3 from "web3";
import { ethers } from 'ethers';
import Web3Modal from 'web3modal'
import { toHex, gasLimit } from "./utils";
import {
  nftcontractSelector,
  accountSelector,
  valueSelector,
  eventSelector,
  web3Selector,
  marketplacecontractSelector,
  signerSelector
} from "../core/selectors/selectors";

import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
//import WalletConnectProvider from "@walletconnect/web3-provider";

import { networkParams } from "./networks";

import MyNFT from '../artifacts/contracts/nft/SIMBAERC721DataUriUpgradeable.sol/SIMBAERC721DataUriUpgradeable.json'
import MARKETPLACE from '../artifacts/contracts/Marketplace/SIMBAMarketUpgradeable.sol/SIMBAMarketUpgradeable.json'
import TREASURY from '../artifacts/contracts/Marketplace/SIMBAMarketUpgradeable.sol/Treasury.json'
import SIMS from '../artifacts/contracts/mockTokens/SIMS.sol/SIMS.json'
import MDR from '../artifacts/contracts/registry/MetadataRegistry.sol/MDR.json';
import IMDR from '../artifacts/contracts/registry/IMetadataRegistry.sol/IMDR.json';
import { providerOptions } from "./providerOptions";
const web3Modal = new Web3Modal({
  network: "any", // optional
  cacheProvider: false, // optional
  providerOptions // required
});

export const getWeb3MaticVigil = async () => {
  let dictionary = {};// 
  dictionary["connection"] = await web3Modal.connect();
  dictionary["provider_MATICVIGIL"] = new ethers.providers.JsonRpcProvider(process.env.MATICVIGIL_API_ID)
  dictionary["network_MATICVIGIL"] = await dictionary["provider_MATICVIGIL"].getNetwork();
  console.log("network_MATICVIGIL", dictionary["network_MATICVIGIL"])

}
export const getWeb3ChainStack = async () => {
  //let network = "0x4";
  let dictionary = {};// 
  //await providerOptions.enable()
  //dictionary["connection"] = await web3Modal.connect();

  dictionary["provider"] = new ethers.providers.Web3Provider(providerOptions);
  dictionary["provider_CHAINSTACK"] = new ethers.providers.JsonRpcProvider(process.env.CHAINSTACK_API_ID)
  dictionary["signer_CHAINSTACK"] = dictionary["provider_CHAINSTACK"].getSigner()
}
export const getWeb3 = async () => {

  //let network = "0x4";
  //let network = "0x4";
  let dictionary = {};// 
  dictionary["connection"] = await web3Modal.connect();
  dictionary["provider"] = new ethers.providers.Web3Provider(dictionary["connection"], "any")
  // dictionary["provider"] = new ethers.providers.JsonRpcProvider(process.env.CHAINSTACK_API_ID, 4);
  dictionary["signer"] = await dictionary["provider"].getSigner();
  dictionary["network"] = await dictionary["provider"].getNetwork();
  let networkID = dictionary["network"].chainId;
  console.log("network_Web3Provider", dictionary["network"])

  let networkDict = networkParams[toHex(networkID)];
  console.log(networkDict)
  dictionary["chainID"] = toHex(networkID);
  dictionary["nftaddress"] = networkDict["nftaddress"];
  dictionary["blockexplorer"] = networkDict["blockExplorerUrls"] + networkDict["nftaddress"];
  dictionary["blockexplorerTx"] = networkDict["blockExplorerUrlsTx"]
  dictionary["blocknumber"] = await dictionary["provider"].getBlockNumber();
  console.log(dictionary["blocknumber"])
  dictionary["nftcontract"] = new ethers.Contract(networkDict["nftaddress"], MyNFT.abi, dictionary["signer"]);
  dictionary["mdraddress"] = networkDict["mdraddress"];
  //dictionary["mdrCode"] = await dictionary["provider"].getCode(dictionary["mdraddress"]);
  // console.log("MDR Code", dictionary["mdrCode"])
  dictionary["blockexplorerMDR"] = networkDict["blockExplorerUrls"] + networkDict["mdraddress"];
  dictionary["mdrcontract"] = dictionary["mdraddress"] ? new ethers.Contract(dictionary["mdraddress"], MDR.abi, dictionary["signer"]) : null;
  dictionary["nftinterfececontract"] = new ethers.Contract(networkDict["nftaddress"], MyNFT.abi, dictionary["provider"]);
  dictionary["marketplacecontract"] = new ethers.Contract(networkDict["nftmarketaddress"], MARKETPLACE.abi, dictionary["signer"])
  dictionary["treasurycontract"] = new ethers.Contract(networkDict["treasuryaddress"], TREASURY.abi, dictionary["signer"])
  dictionary["simscontract"] = new ethers.Contract(networkDict["simstokenaddress"], SIMS.abi, dictionary["signer"])

  const accounts = await dictionary["provider"].send('eth_requestAccounts', []);
  dictionary["account"] = accounts[0];
  console.log('diciontary:', dictionary)
  return dictionary
}

export const disconnectWeb3 = async () => {
  web3Modal.clearCachedProvider();
  let dictionary = {}
  dictionary["connection"] = '';
  dictionary["provider"] = '';
  dictionary["nftaddress"] = '';
  dictionary["network"] = '';
  dictionary["signer"] = '';
  dictionary["nftcontract"] = '';
  dictionary["mdrcontract"] = '';
  dictionary["mdrcontractaddress"] = '';
  dictionary["nftinterfececontract"] = '';
  dictionary["marketplacecontract"] = '';
  dictionary["treasurycontract"] = '';
  dictionary["simscontract"] = '';
  dictionary["simscontract"] = '';
  dictionary["blocknumber"] = '';
  dictionary["blockexplorer"] = '';
  console.log('diciontary:', dictionary)
  return dictionary
}

export const switchNetwork = async (network) => {
  try {
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: network }]
    });

  } catch (switchError) {
    if (switchError.code === 4902) {
      try {
        await window.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [networkParams[toHex(network)]]
        });
      } catch (error) {
        alert("Please add this chain to metamask");
        console.log(error);
      }
    }
  }
}
