import React, {useState, useCallback, useEffect} from 'react';
import { Introduction } from "../../docs/Introduction";
import { Blocks } from "../../docs/Blocks";
import { Marketplace_Docs } from "../../docs/Marketplace_Docs";
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import MenuIcon from '@material-ui/icons/Menu';
import { MDR_Docs } from "../../docs/MDR_Docs";
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import "normalize.css/normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import './styles.scss';
import css from './App.module.css';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';

import LeftSideBar from "../../components/LeftSidebar";
import Footer from "../../components/Footer";
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { SidebarContainer } from "../../components/SidebarContainer";
import RegisterCollectionMDRView from '../RegisterCollectionMDRView';
import MDRView from '../MDRView';
import TokenURIFromMDRView from '../TokenURIFromMDRView';
import MDREvents from '../MDREvents';
import SimbaBlocksIcon from '../../assets/images/SIMBA-Blocks-logo-h-White 1.png';

import { loadWeb3, disconnect } from "../../core/interactions/interactions";

import {
  Button,
  NavbarDivider,
} from "@blueprintjs/core";
import {TitleApp} from '../../components/TitleApp';
import {Mint_Docs} from '../../docs/Mint_Docs';
import {SDK} from '../../docs/SDK';
import RegisterCollectionView from '../RegisterCollectionView';
import MintView from '../MintView';
import ConnectionView from '../ConnectionView';
import Web3EventsView from '../Web3Events';
import RoleView from '../RoleView';
import LibraryView from '../LibraryView';
import TokenURIView from '../TokenURIView';
import { useDispatch, useSelector } from 'react-redux';

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const App = () => {
  const account = useSelector(state => state.connection.account);
  const dispatch = useDispatch();
  const [mobileOpen, setMobileOpen] = useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const handleDrawerToggle = useCallback(() => {
    setMobileOpen(!mobileOpen);
  }, [mobileOpen]);

  const handleRouteChange = useCallback(() => {
    setMobileOpen(false);
  }, []);

  const connectBlockchain = useCallback(async (e) => {
    e.preventDefault();
    const myWeb3 = await loadWeb3(dispatch);

    //subscribeToAccountsChanging(dispatch, myWeb3.account);
  }, [dispatch]);

  const disconnectBlockchain = useCallback(async (e) => {
    e.preventDefault();
    const myWeb3 = await disconnect(dispatch);
    //subscribeToAccountsChanging(dispatch, myWeb3);
  }, [dispatch]);

  const drawer = (
    <SidebarContainer>
      <div className={css.sidebar}>
        <LeftSideBar />
      </div>
    </SidebarContainer>
  );
  
  const container = window !== undefined ? () => window.document.body : undefined;

  return (
    <Router>
      <div className={css.container}>
        <div className={css.header}>
          <AppBar fixedToTop>
            <Toolbar className="navbar" >
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className={classes.menuButton}
              >
                <MenuIcon />
              </IconButton>
              <Grid item xs justifyContent="space-evenly" >
                <Typography justifyContent="flex-start" variant="h6">
                  <img src={SimbaBlocksIcon} alt=""/>
                  NFT Block
                </Typography>
              </Grid>

              <NavbarDivider />

              <Grid item xs={8} container direction="column" justify="center" alignItems="center">
                <TitleApp></TitleApp>
              </Grid>

              <NavbarDivider />

              <Grid item xs={2} container justifyContent="flex-end">

                <div>{!account ? (
                  <Button onClick={connectBlockchain}>Connect Wallet</Button>
                ) : (
                  <Button onClick={disconnectBlockchain}>Disconnect</Button>
                )}</div>
                <div className={css.account}>{account} </div>
              </Grid>
            </Toolbar>
          </AppBar>

        </div>
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <SidebarContainer>
            <div className={css.sidebar}>
              <LeftSideBar onChange={handleRouteChange}/>
            </div>
          </SidebarContainer>
        </Hidden>
        {/*CONTENT*/}
        <div className={css.content}>
          <Switch>
            <Route exact path="/" component={Introduction} />
            <Route
              path="/docs/introduction"
              component={Introduction}
            />
            <Route
              path="/docs/blocks"
              component={Blocks}
            />
            <Route
              path="/docs/Mint_Docs"
              component={Mint_Docs}
            />
            <Route
              path="/docs/Marketplace_Docs"
              component={Marketplace_Docs}
            />
            <Route
              path="/docs/MDR_Docs"
              component={MDR_Docs}
            />
            <Route
              path="/docs/SDK"
              component={SDK}
            />
            <Route
              path="/drop/mint"
              component={MintView}
            />
            <Route
              path="/marketplace/register-collection"
              component={RegisterCollectionView}
            />
            <Route
              path="/mdr/register-to-mdr"
              component={RegisterCollectionMDRView}
            />
            <Route path="/drop/connect" component={ConnectionView} />

            <Route
              path="/drop/events" component={Web3EventsView}
            />
            <Route
              path="/drop/library" component={LibraryView}
            />
            <Route
              path="/drop/uri" component={TokenURIView}
            />
            <Route
              path="/drop/role" component={RoleView}
            />
            <Route
              path="/mdr/mdr" component={MDRView}
            />
            <Route
              path="/mdr/mdr-uri" component={TokenURIFromMDRView}
            />
            <Route
              path="/mdr/register-to-mdr" component={RegisterCollectionMDRView}
            />
            <Route
              path="/mdr/mdr-events" component={MDREvents}
            />
          </Switch>
        </div>
        <div className={css.footer}>
          <Footer />
        </div>
      </div>
    </Router>
  );
}

export default App;