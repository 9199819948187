/* eslint-disable no-unused-vars */
import { getWeb3ChainStack, getWeb3, disconnectWeb3, switchNetwork } from "../../configs/getWeb3";
import {
  connectionLoaded,
  contractReadOnlyViewLoaded,
  marketplacecontractLoaded,
  nftcontractLoaded,
  mdrcontractLoaded,
  accountLoaded,
  valueLoaded,
  eventLoaded,
  libraryLoaded,
  nftMinted,
  mdrSubmission,
  imageUpdated,
  dispatchMinterCreated,
  tokenURILoaded,
  tokenURIFromMDRLoaded,
  marketplaceEventLoaded,
  treasurycontractLoaded,
  SIMScontractLoaded,
  collectionRegistered,
  collectionRegisteredToMDR,
  providerLoaded,
  signerLoaded,
  networkLoaded,
  chainIDLoaded,
  treasuryLoaded,
  NFTContractAddressLoaded,
  MDRContractAddressLoaded,
  blockNumberLoaded,
  BlockExplorerLoaded,
  chainstackWeb3Loaded,
  MDRBlockExplorerLoaded,
  MDReventLoaded,
  TxBlockExplorerLoaded
} from "../actions/actions-connection";
import { ethers } from 'ethers'
import { getString } from '../libs/lib-asset-helpers'

import { toHex, gasLimit } from "../../configs/utils";

const keccak256 = require('keccak256')
function convert_to_bytes32_array(string, length) {
  let bytes32_arr = []
  let bytes = Buffer.alloc(32 * length)
  bytes.write(string)
  for (let i = 0; i < length; i++) {
    let part = Buffer.alloc(32)
    bytes.copy(part, 0, 32 * i, 32 + 32 * i)
    bytes32_arr.push('0x' + part.toString('hex'))
  }
  return bytes32_arr
}
export const loadWeb3 = async (dispatch) => {
  const web3 = await getWeb3();

  dispatch(connectionLoaded(web3.connection));
  dispatch(providerLoaded(web3.provider));
  dispatch(NFTContractAddressLoaded(web3.nftaddress));
  dispatch(MDRContractAddressLoaded(web3.mdraddress));
  dispatch(BlockExplorerLoaded(web3.blockexplorer));
  dispatch(TxBlockExplorerLoaded(web3.blockexplorerTx));
  dispatch(MDRBlockExplorerLoaded(web3.blockexplorerMDR));
  dispatch(networkLoaded(web3.network));
  dispatch(blockNumberLoaded(web3.blocknumber));
  dispatch(chainIDLoaded(web3.chainID));
  dispatch(signerLoaded(web3.signer));
  dispatch(nftcontractLoaded(web3.nftcontract));
  dispatch(mdrcontractLoaded(web3.mdrcontract));
  dispatch(contractReadOnlyViewLoaded(web3.nftinterfececontract));
  dispatch(marketplacecontractLoaded(web3.marketplacecontract));
  dispatch(treasurycontractLoaded(web3.treasurycontract));
  dispatch(SIMScontractLoaded(web3.simscontract));
  dispatch(accountLoaded(web3.account));
  // need to test account changed
  //web3.provider.on('accountsChanged', dispatch(accountLoaded(web3.account)));
  return web3;
}

export const loadChainStackRPC = async (dispatch) => {
  const web3 = await getWeb3ChainStack();
  dispatch(chainstackWeb3Loaded(web3.provider_CHAINSTACK));
  dispatch(NFTContractAddressLoaded(web3.nftaddress));
  dispatch(MDRContractAddressLoaded(web3.mdraddress));
  dispatch(networkLoaded(web3.network));
  dispatch(blockNumberLoaded(web3.blocknumber));
  dispatch(chainIDLoaded(web3.chainID));
  dispatch(signerLoaded(web3.signer));
  dispatch(nftcontractLoaded(web3.nftcontract));
  dispatch(mdrcontractLoaded(web3.mdrcontract));
  dispatch(contractReadOnlyViewLoaded(web3.nftinterfececontract));
  dispatch(marketplacecontractLoaded(web3.marketplacecontract));
  dispatch(treasurycontractLoaded(web3.treasurycontract));
  dispatch(SIMScontractLoaded(web3.simscontract));
  dispatch(accountLoaded(web3.account));
  // need to test account changed
  //web3.provider.on('accountsChanged', dispatch(accountLoaded(web3.account)));
  return web3;
}


export const disconnect = async (dispatch) => {
  const web3 = await disconnectWeb3();
  dispatch(connectionLoaded(web3.connection));
  dispatch(NFTContractAddressLoaded(web3.nftaddress))
  dispatch(MDRContractAddressLoaded(web3.mdraddress));
  dispatch(providerLoaded(web3.provider));
  dispatch(networkLoaded(web3.network));
  dispatch(chainIDLoaded(web3.network.chainId));
  dispatch(signerLoaded(web3.signer));
  dispatch(nftcontractLoaded(web3.nftcontract));
  dispatch(mdrcontractLoaded(web3.mdrcontract));
  dispatch(contractReadOnlyViewLoaded(web3.nftinterfececontract));
  dispatch(marketplacecontractLoaded(web3.marketplacecontract));
  dispatch(treasurycontractLoaded(web3.treasurycontract));
  dispatch(SIMScontractLoaded(web3.simscontract));
  dispatch(accountLoaded(web3.account));
  dispatch(blockNumberLoaded(web3.blocknumber));
  dispatch(BlockExplorerLoaded(web3.blockexplorer));
  await loadLibrary(dispatch, web3.nftcontract, web3.network.chainId, web3.blocknumber)
  return web3;
}
export const switched = async (dispatch, network) => {
  await switchNetwork(network);
  debugger
  await disconnect(dispatch);
}
export const loadLibrary = async (dispatch, contract, chainId, block_number) => {
  const events = await loadPastEvents(dispatch, contract, chainId, block_number);
  const items = await Promise.all((events || []).map(async event => {
    const tokenURI = await contract.tokenURI(event.args.tokenId._hex);
    const infoJsonString = decodeURIComponent(tokenURI).split("data:application/json;base64,")[1];
    var infoJson = window.atob(infoJsonString);
    const info = JSON.parse(infoJson);
    info.image = decodeURIComponent(info.image);

    let item = {
      ...info,
      tokenId: event.args.tokenId._hex,
      tokenURI
    }
    return item
  }));
  dispatch(libraryLoaded(items));
  return items;
}


export const loadPastEvents = async (dispatch, contract, chainID, block_number) => {
  //let events = contract.filters.Transfer()
  let events = "";
  if (typeof contract == 'undefined' || contract === "") {
    dispatch(eventLoaded(events))
  } else {
    try {
      let filter = await contract.filters.Transfer();
      if (chainID === "0x13881") {
        console.log('polygon chain', block_number);
        events = await contract.queryFilter(filter, 26024547, block_number);//block_number - 100000, block_number);
      } else {
        events = await contract.queryFilter(filter);
      } dispatch(eventLoaded(events));
    }
    catch (error) { console.log(error) }

    return events;
  }
}

export const loadMarketplaceEvents = async (dispatch, contract, chainID, block_number, event_choice) => {
  //let events = contract.filters.Transfer()
  let events = "";
  if (typeof contract == 'undefined' || contract === "") {
    dispatch(marketplaceEventLoaded(events))
  } else {
    try {
      // let filter = await contract.filters.CollectionRegistered();
      if (chainID === "0x13881") {
        console.log('polygon chain', block_number);
        //2602457
        events = await contract.queryFilter("*", 26011891, 'latest');//block_number - 100000, block_number);
      } else {
        events = await contract.queryFilter("*");
      }
      dispatch(marketplaceEventLoaded(events));
    }
    catch (error) { console.log(error) }

    return events;
  }
}

export const loadMDREvents = async (dispatch, contract, chainID, block_number, event_choice) => {
  //let events = contract.filters.Transfer()
  let events = "";
  if (typeof contract == 'undefined' || contract === "") {
    dispatch(MDReventLoaded(events))
  } else {
    try {
      let filter = await contract.filters.CollectionRegistered();
      if (chainID === "0x13881") {
        console.log('polygon chain', block_number);
        //2602457
        events = await contract.queryFilter("*", 26011891, 'latest');//block_number - 100000, block_number);
      } else {
        events = await contract.queryFilter("*");
      } dispatch(MDReventLoaded(events));
    }
    catch (error) { console.log(error) }

    return events;
  }
}

export const loadTokenURI = async (dispatch, contract, tokenId) => {
  const value = await contract.tokenURI(tokenId);

  dispatch(valueLoaded(value));
  return value;
}

export const getTokenURI = async (dispatch, contract, tokenId) => {

  const tokenURI = await contract.tokenURI(tokenId);

  dispatch(tokenURILoaded(tokenURI));
  return tokenURI;
}

export const getTokenURIFromMDR = async (dispatch, contract, collectionId, tokenId) => {
  debugger
  const tokenURI = await contract.tokenURI(collectionId, tokenId);

  dispatch(tokenURIFromMDRLoaded(tokenURI));
  ;
  return tokenURI;
}

export const setMinter = async (dispatch, new_minter, contract) => {
  const minterRole = await contract.MINTER_ROLE();
  debugger
  console.log(minterRole)
  try {
    const new_minter_created = await contract.grantRole(
      minterRole,
      new_minter,
      {
        gasLimit: gasLimit
      }
    );

    dispatch(dispatchMinterCreated(new_minter_created));
    return new_minter_created;
  } catch (error) {
    console.log('Error calling grantRole: ', error);

  }
}

export const updateImage = async (dispatch, contract, tokenId, fileUrl) => {
  const image = await convert_to_bytes32_array(fileUrl, 4)
  try {
    const imageUpdated = await contract.updateImage(tokenId, image);
    dispatch(imageUpdated(updateImage));
    return imageUpdated;
  } catch (e) {
    console.log('Error calling updateImage: ', e);
  }
}

export const mintNFT = async (dispatch, contract, account, file, desc, fileUrl, metadataName) => {
  const fileString = await getString(file)
  console.log(fileString)
  //const blocks_api_routing_and_function_name
  const hashFunction = 27;
  const contentHash = keccak256(fileString).toString('hex')
  const prefixed_contentHash = '0x' + contentHash
  const metadataName1 = ethers.utils.formatBytes32String(metadataName)
  const desc1 = convert_to_bytes32_array(desc, 4)
  const image1 = convert_to_bytes32_array(fileUrl, 4)
  console.log("NFT var type", typeof account)

  try {
    const mintedNFT = await contract.mint(
      account,
      hashFunction,
      prefixed_contentHash,
      metadataName1,
      desc1,
      image1,
      {
        gasLimit: gasLimit
      }
    );

    dispatch(nftMinted(mintedNFT));
    return mintedNFT;
  } catch (error) {

    console.log('Error calling mint: ', error);
  }
}

export const submitMetadata = async (
  dispatch,
  contract,
  NFTCollection,
  file,
  desc,
  fileUrl,
  metadataName,
  tokenId,
  // paymentMethod
) => {
  const fileString = await getString(file)
  console.log(fileString)

  const hashFunction = 27;
  const contentHash = keccak256(fileString).toString('hex')
  const prefixed_contentHash = '0x' + contentHash
  const metadataName1 = ethers.utils.formatBytes32String(metadataName)
  const desc1 = convert_to_bytes32_array(desc, 4)
  const image1 = convert_to_bytes32_array(fileUrl, 4)

  let encoder = new ethers.utils.AbiCoder()
  const metadata = encoder.encode(
    [
      "bytes32",
      "bytes32[4]",
      "bytes32[4]",
      "bytes32",
      "uint64 "
    ],
    [
      metadataName1,
      desc1,
      image1,
      prefixed_contentHash,
      hashFunction
    ]
  )
  //const paymentMethod = 1;
  /// if payment method is MATIC
  // const fee = paymentMethod === 1 ? await contract.submissionFee() : 0

  try {

    const MetadataInfo = {
      tokenId: tokenId,
      metadata: metadata
    }
    console.log("NFT var type", typeof NFTCollection)
    console.log("NFT var type", typeof [MetadataInfo])
    //console.log("Payment var type", typeof paymentMethod)
    const submittedMD = await contract.submitMetadata(
      NFTCollection,
      [MetadataInfo],
      false, // boolean for attributesIncluded
      // paymentMethod,
      {
        gasLimit: gasLimit
      }
    )
    debugger

    dispatch(mdrSubmission(submittedMD));
    return submittedMD;
  } catch (error) {

    console.log('Error calling MDR Backup: ', error);
  }
}
/*
export const updateImage = async (dispatch, contract, tokenId, file, fileUrl) => {
  const fileString = await getString(file)
  const hashFunction = 27;
  const contentHash = keccak256(fileString)
  const metadataName1 = ethers.utils.formatBytes32String(metadataName)
  const desc1 = convert_to_bytes32_array(desc, 4)
  const image1 = convert_to_bytes32_array(fileUrl, 4)
  try {

    const mintedNFT = await contract.mint(
      account,
      hashFunction,
      contentHash,
      metadataName1,
      desc1,
      image1,
      {
        gasLimit: gasLimit
      }
    );

    dispatch(nftMinted(mintedNFT));
    return mintedNFT;
  } catch (error) {

    console.log('Error calling mint: ', error);
  }
}*/

export const registerCollection = async (dispatch, contract, collection_address, owner_address) => {

  let check = contract.address;
  //;

  try {

    const registeredCollection = await contract.registerNFTCollection(
      collection_address,
      owner_address,
      {
        gasLimit: gasLimit
      })

    dispatch(collectionRegistered(registeredCollection));
    return registeredCollection;
  } catch (error) {
    console.log('Error calling registerNFTCollection: ', error);
  }
}

export const registerCollectionToMDR = async (dispatch, contract, collection_address, owner_address, holdersCanSubmit) => {

  try {
    const registeredCollection = await contract.registerCollection(
      collection_address,
      owner_address,
      holdersCanSubmit,
      {
        gasLimit: gasLimit
      })

    dispatch(collectionRegisteredToMDR(registeredCollection));
    return registeredCollection;
  } catch (error) {
    console.log('Error calling registerNFTCollectionToMDR: ', error);
  }
}

export const loadTreasury = async (dispatch, contract) => {

  let check = contract.address;
  //;

  try {

    const treasury = await contract.getTreasury(
      {
        gasLimit: gasLimit
      })

    dispatch(treasuryLoaded(treasury));
    return treasury;
  } catch (error) {
    console.log('Error calling treasury: ', error);
  }
}
