import React from "react";
//import cx from "classnames";
import Typography from '@material-ui/core/Typography';
import styles from "./style.module.css";
import mint from "../../assets/images/mint.png";
import events from "../../assets/images/events.png";
import library from "../../assets/images/library.png";
import fetch from "../../assets/images/fetch.png";
import authorize from "../../assets/images/authorize.png";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CheckIcon from '@material-ui/icons/Check';
import Check from "@material-ui/icons/Check";
import logo from "../../assets/images/MDR-Block-logo-h-Blue.svg";
export const MDR_Docs = props => {
    React.useEffect(() => {
        document.title = `Simba MDR`;

    });
    return (
        <div className={styles.container}>
            <Typography className={styles.h4_title} variant="h4" >
                <img className={styles.item_logo} src={logo} alt='' />
            </Typography>

            <Typography variant="h5">
                <br></br>
                Web3 Data Management
                <br></br>
            </Typography>
            <Typography className={styles.text} >
                <br></br>
                A recent Forbes contributor writes: “The market for non-fungible tokens (NFTs) has skyrocketed. Yet, the blockchain-inspired technology is scratching the surface of what might be possible. Improvements in the way NFTs are created, stored and indexed promise to accelerate this boom in digital collectibles. This enables new types of NFTs that combine digital assets, business logic and data taxonomies.” and goes on to claim that “Metadata sidechains [will] allow opportunities for this nascent market exchange to proliferate, thanks to better data management and support for data schemas”.

                <br></br>
            </Typography>
            <Typography className={styles.text} >
                <br></br>
                SIMBA can lead the way in providing technology, tooling and support for the creation and use of these “metadata sidechains” - providing motivation and support for Web2.0 companies with “big data” assets to take advantage of Web3.0 protocol benefits - including fluid monetization, active community engagement and decentralized trust networks.
            </Typography>
            <Typography className={styles.text} >
                <br></br>
                A leading Web3.0 researcher finds “there is no clear best practice standard of how data should be governed and stored in Web3.0.”* - yet SIMBA is well placed to define this standard. Building on world class smart contracts deployed across blockchains, and leveraging emerging technologies such as decentralized knowledge graphs to create semantically searchable data maps, SIMBA can provide secure, resilient and trusted protocols for decentralized data indexing, storage and search - helping to dismantle the closed silos of data in Web2.0, whilst strengthening Web3.0 infrastructure.
            </Typography>
            <Typography variant="h5">
                <br></br>
                Metadata Registery (MDR) by Simba
                <br></br>
            </Typography>
            <Typography className={styles.text} >
                <List component="nav" aria-label="contacts">
                    <ListItem>
                        <CheckIcon />
                        <ListItemText primary="Provide seamless pathways for Web2.0 data to join Web3.0." />
                    </ListItem>
                    <ListItem >
                        <CheckIcon />
                        <ListItemText primary="Provide semantic index and search capabilities for Web3.0 data." />
                    </ListItem>
                    <ListItem >
                        <CheckIcon />
                        <ListItemText primary="Provide resilience and trustworthiness for decentralized Web3.0 data assets." />
                    </ListItem>
                    <ListItem >
                        <CheckIcon />
                        <ListItemText primary="Provide bridging capabilities for all EVM compatible chains" />
                    </ListItem>
                </List>




            </Typography>

        </div>

    );
};


