import React from "react";
//import cx from "classnames";
import Typography from '@material-ui/core/Typography';
import styles from "./style.module.css";
import mint from "../../assets/images/mint.png";
import events from "../../assets/images/events.png";
import library from "../../assets/images/library.png";
import fetch from "../../assets/images/fetch.png";
import logo from "../../assets/images/SIMBA_Market_logo_opti1.png";

export const Marketplace_Docs = props => {
    React.useEffect(() => {
        document.title = `Simba Marketplace`;

    });
    return (
        <div className={styles.container}>

            <Typography className={styles.h4_title} variant="h4" >
                <img className={styles.item_logo} src={logo} />
            </Typography>

            <Typography className={styles.text} >
                <br></br>
                SIMBA Chain is changing the game when it comes to brand power and getting into the NFT space. A lot of services have a massive shared marketplace you can sign up for and sell on, but none of those solutions offer a truly customized branded experience. In addition, a lot of services require you to go through them to mint NFTs or manage operations of the marketplace.
                <br></br>
            </Typography>
            <Typography >
                <br></br>
                While SIMBA can assist in the complete operation of your marketplace, we have developed a user-friendly backend that enables anyone to mints NFTs; no coding or blockchain experience require. This gives you the freedom and agility to create and sell NFTs whenever you want. In addition, the process we developed to mint NFTs not only ensures security on the blockchain, but they are also verifiable through the metadata that is added to the blockchain with a digital fingerprint of the asset, not just a URL redirect that could potentially break or disappear in the future.
            </Typography>
            <Typography className={styles.text}  >
                <br></br>
                Our feature-rich platform is packed with tools and capabilities are expanding all the time. You can mint unique one-of-a-kind NFTs or mint in bulk for limited runs, whatever fits your strategy.
            </Typography>
        </div>

    );
};


