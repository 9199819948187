import constants from '../types'

export function connectionLoaded(connection) {
    return {
        type: constants.CONNECTION_LOADED,
        connection
    }
}
export function blockNumberLoaded(block_number) {
    return {
        type: constants.BLOCKNUMBER_LOADED,
        block_number
    }
}

export function TxBlockExplorerLoaded(blockexplorerTx) {
    return {
        type: constants.TX_BLOCKEXPLORER,
        blockexplorerTx
    }
}
export function BlockExplorerLoaded(blockexplorerurl) {
    return {
        type: constants.BLOCKEXPLORER,
        blockexplorerurl
    }
}

export function NFTContractAddressLoaded(nftaddress) {
    return {
        type: constants.NFT_CONTRACT_ADDRESS_LOADED,
        nftaddress
    }
}



export function MDRBlockExplorerLoaded(mdrblockexplorerurl) {
    return {
        type: constants.MDR_BLOCKEXPLORER_LOADED,
        mdrblockexplorerurl
    }
}
export function MDRContractAddressLoaded(mdraddress) {
    return {
        type: constants.MDR_CONTRACT_ADDRESS_LOADED,
        mdraddress
    }
}

export function accountLoaded(account) {
    return {
        type: constants.ACCOUNT_LOADED,
        account
    }
}
export function providerLoaded(provider) {
    return {
        type: constants.PROVIDER_LOADED,
        provider
    }
}
export function networkLoaded(network) {
    return {
        type: constants.NETWORK_LOADED,
        network
    }
}
export function chainstackWeb3Loaded(chainstack_web3) {
    return {
        type: constants.CHAINSTACK_NETWORK_LOADED,
        chainstack_web3
    }
}
export function mumbaiWeb3Loaded(mumbai_web3) {
    return {
        type: constants.MUMBAI_NETWORK_LOADED,
        mumbai_web3
    }
}


export function chainIDLoaded(chainID) {
    return {
        type: constants.CHAIN_ID_LOADED,
        chainID
    }
}

export function signerLoaded(signer) {
    return {
        type: constants.SIGNER_LOADED,
        signer
    }
}
export function treasuryLoaded(treasury) {
    return {
        type: constants.GET_TREASURY,
        treasury
    }
}

export function contractViewLoaded(contract_view_only) {
    return {
        type: constants.CONTRACT_VIEW_LOADED,
        contract_view_only
    }
}

export function contractReadOnlyViewLoaded(contract_read_only_instance) {
    return {
        type: constants.CONTRACT_READONLY_INSTANCE,
        contract_read_only_instance
    }
}

export function treasurycontractLoaded(treasury_contract) {
    return {
        type: constants.TREASURY_CONTRACT_LOADED,
        treasury_contract
    }
}

export function SIMScontractLoaded(SIMS_contract) {
    return {
        type: constants.SIMS_CONTRACT_LOADED,
        SIMS_contract
    }
}

export function nftcontractLoaded(nft_contract) {
    return {
        type: constants.NFT_CONTRACT_LOADED,
        nft_contract
    }
}

export function mdrcontractLoaded(mdr_contract) {
    return {
        type: constants.MDR_CONTRACT_LOADED,
        mdr_contract
    }
}

export function marketplacecontractLoaded(marketplace_contract) {
    return {
        type: constants.MARKETPLACE_CONTRACT_LOADED,
        marketplace_contract
    }
}


export function collectionRegistered(registered_collection) {
    return {
        type: constants.REGISTERED_COLLECTION,
        registered_collection
    }
}

export function collectionRegisteredToMDR(registered_collection) {
    return {
        type: constants.REGISTERED_COLLECTION_MDR,
        registered_collection
    }
}

export function tokenURILoaded(tokenURI) {
    return {
        type: constants.FETCH_TOKENURI,
        tokenURI
    }
}

export function tokenURIFromMDRLoaded(tokenURI) {
    return {
        type: constants.FETCH_TOKENURI_MDR,
        tokenURI
    }
}

export function valueLoaded(value) {
    return {
        type: constants.VALUE_LOADED,
        value
    }
}

export function nftMinted(nft) {
    return {
        type: constants.NFT_MINTED,
        nft
    }
}

export function mdrSubmission(meta) {
    return {
        type: constants.MDR_SUBMISSION,
        meta
    }
}

export function imageUpdated(imageUpdated) {
    return {
        type: constants.IMAGE_UPDATED,
        imageUpdated
    }
}

export function eventLoaded(events) {
    return {
        type: constants.EVENTS_LOADED,
        events
    }
}


export function MDReventLoaded(mdrevents) {
    return {
        type: constants.MDR_EVENTS_LOADED,
        mdrevents
    }
}

export function marketplaceEventLoaded(marketplaceEvents) {
    return {
        type: constants.MARKETPLACE_EVENTS_LOADED,
        marketplaceEvents
    }
}

export function libraryLoaded(items) {
    return {
        type: constants.LIBRARY_LOADED,
        libraryItems: items
    }
}

export function dispatchMinterCreated(transaction) {
    return {
        type: constants.CREATE_MINTER,
        transaction,
        success: true
    }
}
