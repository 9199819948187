import React, { useCallback, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Button, Icon, Grid, Table, TableBody, TableCell, TableHead, TableRow, TextField, Paper, Typography } from "@material-ui/core";
import { TitleMDR } from "../../components/TitleMDR"
import { styles } from './styles.scss'
import { getTokenURIFromMDR, loadPastEvents } from "../../core/interactions/interactions";

const TokenURIFromMDRView = ({ classes }) => {
  const [tokenId, setTokenId] = useState('');
  const [collectionId, setCollectionId] = useState('');
  const [rows, setRows] = useState([]);
  const mdr_contract = useSelector(state => state.connection.mdr_contract);
  const blockexplorerurl = useSelector(state => state.connection.blockexplorerurl);
  const block_number = useSelector(state => state.connection.block_number);
  const chainID = useSelector(state => state.connection.chainID);
  const dispatch = useDispatch();
  useEffect(() => {
    loadPastEvents(dispatch, mdr_contract, chainID, block_number);
  }, [dispatch, mdr_contract, chainID, block_number]);

  const handleSubmit = useCallback(async (evt) => {
    evt.preventDefault();
    try {
      const tokenURI = await getTokenURIFromMDR(dispatch, mdr_contract, collectionId, tokenId);
      const infoJsonString = decodeURIComponent(tokenURI).split("data:application/json;base64,")[1];
      var infoJson = window.atob(infoJsonString);
      const info = JSON.parse(infoJson);
      info.image = decodeURIComponent(info.image);
      console.log(info)
      setRows([info]);
    } catch (e) {
      console.log(e);
    }
  }, [dispatch, mdr_contract, collectionId, tokenId]);

  const handleTokenIdChange = useCallback(e => {
    setTokenId(e.target.value);
  }, []);

  const handleCollectionChange = useCallback(e => {
    setCollectionId(e.target.value);
  }, []);
  return (
    <div className="container"  >
      <Grid container
        direction="column">
        <Grid direction="column" >

          <TitleMDR></TitleMDR>
        </Grid>
        <Grid direction="column" >
          <br />
          <a href={blockexplorerurl} target="_blank" rel="noreferrer"><u>Click Here for Block Explorer</u></a>
          <br />
        </Grid>
        <Grid direction="column" >
          <form className="form">
            <Grid justifyContent="space-between" direction="column">
              <TextField
                label="Please Enter the Collection Address"
                className="textField"
                style={{ width: '100%' }}
                value={collectionId}
                onChange={handleCollectionChange}
                margin="normal"
              />
            </Grid>
            <Grid justifyContent="space-between" direction="column">
              <TextField
                label="Please Enter the Token Id"
                style={{ width: '100%' }}
                className="textField"
                value={tokenId}
                onChange={handleTokenIdChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs container direction="column" >
              <Button
                onClick={handleSubmit}
                disabled={!tokenId}
                variant="contained"
                style={{
                  backgroundColor: "#e7e9ea",
                }}>
                Get TokenURI
              </Button>
            </Grid>
          </form>
        </Grid>
        <Grid justifyContent="space-between" direction="column">
          {rows.length > 0 && <Paper className={classes.root}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Content hash</TableCell>
                  <TableCell>Image</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell>{row.description}</TableCell>
                    <TableCell>{row.contentHash}</TableCell>
                    <TableCell><img className={classes.image} src={row.image} alt="the thing" /></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>}
        </Grid>
      </Grid>
    </div>
  )
}

export default withStyles(styles)(TokenURIFromMDRView)
